.partners {
	text-align: center;
	&__title {
		position: relative;
		top: 30px;
		display: inline-block;
		padding: 10px 30px;
		background-color: #fff;
		text-align: center;
		font-size: 40px;
		font-weight: 700;
		color: $title;
		line-height: 1;
		@include respond-to('large') {
			top: 25px;
			font-size: 30px;
		}
		@include respond-to('medium') {
			font-size: 25px;
		}
		@include respond-to('small') {
			top: 20px;
		}
		@include respond-to('extra-small') {
			font-size: 20px;
		}
	}
	&__items {
		padding: 30px 20px;
		border: 2px solid #e88289;
		display: flex;
		justify-content: space-evenly;
		flex-flow: row wrap;
	}
	&__item {
		padding: 0 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		& img {}
	}
}