.side-nav {
	position: fixed;
	#{$right}: 0;
	top: 230px;
	z-index: 11;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	@include respond-to('medium') {
		top: auto;
		bottom: 20px;
	}
	&__item {
		width: 60px;
		height: 60px;
		margin-top: 1px;
		padding: 10px;
		background-color: $accent;
		@include link_no-hover(#fff);
		text-align: center;
		@include respond-to('huge') {
			width: 40px;
			height: 40px;
			padding: 5px;
		}
		@include respond-to('medium') {
			width: 30px;
			height: 30px;
			padding: 2px;
		}
		&:first-child {
			height: 160px;
			display: flex;
			flex-flow: column nowrap;
			align-items: center;
			justify-content: flex-end;
			@include respond-to('huge') {
				height: 130px;
			}
			@include respond-to('large') {
				height: 40px;
			}
			@include respond-to('medium') {
				height: 30px;
			}
		}
		& span {
			min-width: 65px;
			color: #fff;
			transform: rotate(-90deg);
			@include respond-to('large') {
				display: none;
			}
			& ~ img {
				height: 49px;
				width: 23px;
				margin-top: 32px;
				@include respond-to('huge') {
					margin-top: 20px;
				}
				@include respond-to('large') {
					margin-top: 0;
					height: 30px;
					width: 20px;
				}
				@include respond-to('medium') {
					height: 25px;
				}
			}
		}
	}
}