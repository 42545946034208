.main-header {
	width: 100%;
	z-index: 99;
	position: fixed;
	top: 0;
	box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
	transition: all 0.4s ease;
}

.header-desktop {
	@include respond-to('medium') {
		display: none;
	}
	&__top {
		display: flex;
		justify-content: space-between;
		background-color: $title;
		& .section-limit {
			height: 100px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			@include respond-to('large') {
				height: 90px;
			}
		}
	}
	&__bottom {
		background-color: $accent;
	}
}

.logo {
	& a {
		display: flex;
		align-items: flex-end;
		@include link_no-hover(#fff);
		& img {}
		& span {
			font-size: 26px;
			font-weight: 700;
			margin-#{$right}: 15px;
		}
	}
}

.phone {
	padding-top: 31px;
	& a {
		@include link_no-hover(#fff);
		font-size: 26px;
		font-weight: 700;
	}
}

.main-navi {
	& > ul {
		height: 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0;
		padding: 0;
		& > li {
			position: relative;
			list-style: none;
			& > a {
				display: block;
				font-size: 19px;
				padding-bottom: 3px;
				padding-top: 6px;
				color: #fff;
				line-height: 1;
				font-weight: 400;
				border-bottom: 3px solid transparent;
				transition: border 0.2s ease;
				@include respond-to('large') {
					font-size: 19px;
				}
				&:hover {
					border-bottom: 3px solid $title;
					text-decoration: none;
				}
				&:focus,
				&:active {
					text-decoration: none;
				}
				&.active {
					border-bottom: 3px solid $title;
				}
			}
		}
	}
}

.header-mobile {
	display: none;
	height: 70px;
	background-color: $title;
	@include respond-to('medium') {
		display: block;
	}
	&__logo {
		margin-#{$left}: 15px;
		margin-top: 5px;
		float: $left;
		& > img {
			// width: 200px;
			max-height: 60px;
		}
	}
	&__tel {
		display: inline-block;
		margin-top: 17px;
		i {
			color: $accent;
			font-size: 32px;
		}
	}
}

// .main-header.scrolling {
// 	.header-desktop {
// 		height: 80px;
// 	}
// 	.main-navi {
// 		& > ul {
// 			margin-top: 25px;
// 		}
// 	}
// 	.wrap-lang {
// 		margin-top: 25px;
// 	}
// 	.logo {
// 		top: 15px;
// 	}
// }
