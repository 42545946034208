.wrap-content {
	padding: 60px 0;
	@include respond-to('large') {
		padding: 50px 0;
	}
	@include respond-to('medium') {
		padding: 40px 0;
	}
	@include respond-to('small') {
		padding: 30px 0;
	}
	@include respond-to('extra-small') {
		padding: 25px 0;
	}
}


.title {
	margin-top: 0;
	margin-bottom: 60px;
	font-size: 40px;
	font-weight: 700;
	line-height: 1;
	color: $title;
	text-align: center;
	@include respond-to('larger') {
		font-size: 40px;
		margin-bottom: 50px;
	}
	@include respond-to('large') {
		font-size: 35px;
		margin-bottom: 40px;
	}
	@include respond-to('medium') {
		font-size: 30px;
		margin-bottom: 30px;
	}
	@include respond-to('small') {
		font-size: 25px;
		margin-bottom: 25px;
	}
	@include respond-to('extra-small') {
		font-size: 20px;
	}
	&_decor {
		padding-bottom: 15px;
		position: relative;
		&::after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			margin: 0 auto;
			width: 180px;
			height: 4px;
			background-color: $accent;
		}
	}
	&_m0 {
		margin: 0;
	}
}

.wrap-all-content-not-banner,
.padding-top-catalog-item {
	margin-top: 180px;
	@include respond-to('medium') {
		margin-top: 80px;
	}
}

.hr-content {
	border-top: 1px solid #d9d9d9;
	margin: 0 0 20px;
}

.content-text {
	h2,
	h3 {
		font-size: 27px;
		font-weight: 600;
		line-height: 1.2;
		color: $title;
		margin: 20px 0 20px;
		@include respond-to('large') {
			font-size: 23px;
		}
		@include respond-to('extra-small') {
			font-size: 18px;
		}
	}
	p {
		font-size: 18px;
		color: $title;
		font-weight: 500;
		line-height: 1.4;
		@include respond-to('small') {
			font-size: 16px;
			text-align: justify;
		}
	}
	iframe {
		@include respond-to('small') {
			text-align: center !important;
			margin: 15px auto !important;
			width: 100% !important;
			height: auto !important;
			float: none !important;
			display: block !important;
		}
	}
	img {
		@include respond-to('small') {
			float: none !important;
			text-align: center !important;
			width: auto !important;
			height: auto !important;
			margin: 15px auto !important;
		}
	}
	& a {
		color: $title;
		font-weight: 900;
	}
	& .text {
		& p {
			font-size: 22px;
			text-align: center;
			color: $text;
		}
	}
}

.gallery-cats {
	max-width: 1050px;
	width: 100%;
	text-align: center;
	margin: 0 auto 50px;
	@include respond-to('medium') {
		margin: 0 auto 30px;
	}
	& a {
		text-align: center;
		margin-top: 40px;
		font-size: 22px;
		@include link_on-hover($title, $accent);
		@include respond-to('small') {
			font-size: 20px;
		}
		@include respond-to('extra-small') {
			font-size: 18px;
		}
		&.active {
			color: $accent;
		}
	}
}

.gallery {
	padding-bottom: 70px;
	@include respond-to('large') {
		padding-bottom: 50px;
	}
	@include respond-to('1100') {
		padding-bottom: 40px;
	}
	@include respond-to('medium') {
		padding-bottom: 30px;
	}
	&__item {
		width: 32%;
		font-size: initial;
		padding: 3px;
		float: $right;
		@include respond-to('large') {
			width: 33.33%;
		}
		@include respond-to('small') {
			width: 50% !important;
			height: 350px !important;
		}
		@include respond-to('extra-small') {
			width: 100% !important;
			height: 250px !important;
		}
		&:nth-child(6n + 1) {
			width: 36%;
			height: 590px;
			@include respond-to('large') {
				height: 490px;
				width: 33.33%;
			}
		}
		&:nth-child(6n + 2) {
			height: 570px;
			@include respond-to('large') {
				height: 470px;
			}
		}
		&:nth-child(6n + 3) {
			height: 320px;
			float: $left;
			@include respond-to('large') {
				height: 220px;
			}
		}
		&:nth-child(6n + 4) {
			float: $left;
			height: 590px;
			@include respond-to('large') {
				height: 490px;
			}
		}
		&:nth-child(6n + 5) {
			float: $left;
			height: 340px;
			@include respond-to('large') {
				height: 240px;
			}
		}
		&:nth-child(6n + 6) {
			height: 320px;
			width: 36%;
			@include respond-to('large') {
				height: 220px;
				width: 33.33%;
			}
		}
		& img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
}

.gallery-item {
	display: block;
	height: 100%;
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}