.wrapper-banners {
	margin-top: 139px;
	position: relative;
	width: 100%;
	height: 500px;
	overflow: hidden;
	@include respond-to('large') {
		margin-top: 130px;
	}
	@include respond-to('medium') {
		height: 400px;
		margin-top: 70px;
	}
	@include respond-to('small') {
		height: auto;
	}
	@include respond-to('extra-small') {
		// height: 200px;
	}
}

.wrap-banner-scroll {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include respond-to('medium') {
		position: static;
	}
}

.banner-item {
	width: 100%;
	min-height: 500px;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	@include respond-to('medium') {
		min-height: 400px;
	}
	@include respond-to('small') {
		min-height: 300px;
	}
	@include respond-to('extra-small') {
		min-height: 300px;
	}
	&__text {
		position: absolute;
		left: 0;
		right: 0;
		top: 15%;
		margin: 0 auto;
		max-width: 1430px;
		width: 100%;
		padding: 0 15px;
		@include respond-to('small') {
			// position: static;
		}
	}
}

.banner-text {
	max-width: 600px;
	width: 100%;
	text-align: $right;
	@include respond-to('1550') {
		max-width: 560px;
		padding-#{$right}: 40px;
	}
	@include respond-to('larger') {
		padding-#{$right}: 35px;
	}
	@include respond-to('large') {
		max-width: 450px;
	}
	@include respond-to('medium') {
		padding-#{$right}: 15px;
		max-width: 400px;
	}
	@include respond-to('small') {
		// max-width: 300px;
		// padding: 10px;
		// margin-#{$right}: 30px;
		// background-color: rgba($color: #fff, $alpha: 0.3);
		padding: 20px 0 10px;
		max-width: 100%;
	}
	@include respond-to('mobile-m') {
		max-width: 270px;
	}
	&__title {
		margin-bottom: 20px;
		display: inline-block;
		padding-bottom: 5px;
		font-size: 50px;
		font-weight: 700;
		line-height: 1;
		color: $title;
		border-bottom: 4px solid $accent;
		@include respond-to('1550') {
			font-size: 40px;
		}
		@include respond-to('large') {
			font-size: 35px;
		}
		@include respond-to('medium') {
			font-size: 27px;
		}
		@include respond-to('small') {
			font-size: 20px;
		}
	}
	&__subtitle {
		margin-bottom: 20px;
		display: inline-block;
		font-size: 35px;
		font-weight: 700;
		line-height: 1;
		color: $title;
		@include respond-to('1550') {
			font-size: 30px;
		}
		@include respond-to('large') {
			font-size: 25px;
		}
		@include respond-to('medium') {
			font-size: 22px;
		}
		@include respond-to('small') {
			display: block;
			font-size: 18px;
			margin-bottom: 10px;
		}
	}
	&__btn {
		& a {
			font-size: 25px;
			font-weight: 700;
			line-height: 1;
			@include link_on-hover($title, $accent);
			@include respond-to('medium') {
				font-size: 20px;
			}
			@include respond-to('small') {
				font-size: 18px;
			}
		}
	}
}