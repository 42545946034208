footer {
	width: 100%;
	position: relative;
	background-color: #00314b;
	z-index: 1;
	padding: 45px 0 20px;
	@include respond-to('large') {
		padding: 20px 0;
	}
}

.info {
	padding-top: 50px;
}

.ftr-info {
	color: #fff;
	&_bottom {
		text-align: center;
	}
	&_left {
		border-#{$right}: 3px solid $accent;
		margin-top: 50px;
		padding-#{$right}: 150px;
		padding-top: 60px;
		padding-bottom: 30px;
		@include respond-to('larger') {
			padding-#{$right}: 50px;
		}
		@include respond-to('small') {
			padding-#{$right}: 0;
			margin-top: 0px;
			border: none;
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}
	&_right {}
	& p {
		font-size: 20px;
		font-weight: 400;
		line-height: 1.2;
		@include respond-to('small') {
			font-size: 18px;
		}
		@include respond-to('extra-small') {
			font-size: 16px;
		}
		& b {
			font-weight: 700;
		}
	}
	& ul {
		padding: 0;
		margin: 0;
		& li {
			list-style: none;
			position: relative;
			padding-#{$right}: 20px;
			@include respond-to('small') {
				font-size: 18px;
			}
			@include respond-to('extra-small') {
				font-size: 16px;
			}
			&::after {
				content: '';
				position: absolute;
				right: 0;
				top: 11px;
				width: 10px;
				height: 2px;
				background-color: $accent;
			}
		}
	}
	& a {
		@include link_on-hover(#fff, $accent);
	}
}