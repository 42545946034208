.wrapper-all-content {
	background-color: #fff;
	position: relative;
	width: 100%;
}

.hp-boxes-wr {}

.hp-boxes {
	display: flex;
	justify-content: space-evenly;
	margin: 0 -15px;
	flex-flow: row wrap;
	&__item {
		max-width: 350px;
		// flex: 1 1 auto;
		padding: 15px;
		@include respond-to('1100') {}
	}
}

.sec-padd {
	padding: 50px 0;
	@include respond-to('medium') {
		padding: 40px 0;
	}
	@include respond-to('small') {
		padding: 30px 0;
	}
}

.text {
	font-size: 18px;
	color: $text;
	font-weight: 400;
	line-height: 1.2;
	@include respond-to('small') {
		font-size: 16px;
	}
}

.hp-box {
	&__img {
		display: block;
		width: 100%;
		height: 200px;
		& img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
	&__title {
		position: relative;
		margin-top: 10px;
		font-size: 27px;
		font-weight: 900;
		color: $title;
		padding-bottom: 2px;
		text-align: center;
		margin-bottom: 15px;
		@include respond-to('large') {
			font-size: 23px;
		}
		@include respond-to('extra-small') {
			font-size: 18px;
		}
		&::after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			margin: 0 auto;
			width: 100px;
			height: 4px;
			background-color: $accent;
		}
	}
	&__text {
		text-align: justify;
	}
	&__link {
		text-align: $left;
		& a {
			font-size: 18px;
			color: $text;
			font-weight: 700;
			line-height: 1.2;
			@include link_on-hover($text, $title);
		}
	}
}