.section-cookie{
  position: fixed;
  bottom: 0;
  width: 100%; 
  background-color: #ddd;
  &__text{
    color: #000;
    font-size: 18px;
    padding: 20px;
  }
} 

.section-cookie{
  position: fixed;
  bottom: 0;
  width: 100%; 
  background-color: #ddd;
  display: none;
  z-index: 9999;
} 

.section-cookie__text{
  color: #000;
  font-size: 18px;
  padding: 10px 20px 1px;
  @include respond-to('small'){
    font-size: 13px;
    padding: 5px 10px;
  }
}

.section-cookie .close{
  padding: 10px;
}