.hp-advantages-wr {
	background-color: #f2f2f2;
	&__title {
		margin-bottom: 20px;
		font-size: 40px;
		font-weight: 700;
		line-height: 1;
		color: $title;
		text-align: center;
		@include respond-to('larger') {
			font-size: 40px;
		}
		@include respond-to('large') {
			font-size: 35px;
		}
		@include respond-to('medium') {
			font-size: 30px;
		}
		@include respond-to('small') {
			font-size: 25px;
		}
		@include respond-to('extra-small') {
			font-size: 20px;
		}
	}
}

.hp-advantages {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-evenly;
	margin: 0 -15px;
	&__item {
		width: 33.33%;
		padding: 15px;
		@include respond-to('small') {
			width: 50%;
		}
		@include respond-to('mobile-m') {
			padding: 10px 8px;
		}
	}
}

.hp-advantage {
	max-width: 270px;
	margin: 0 auto;
	&__img {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
		& img {}
	}
	&__title {
		position: relative;
		margin-top: 5px;
		font-size: 27px;
		font-weight: 900;
		color: $title;
		padding-bottom: 2px;
		text-align: center;
		margin-bottom: 15px;
		@include respond-to('large') {
			font-size: 22px;
		}
		@include respond-to('medium') {
			font-size: 20px;
		}
		@include respond-to('extra-small') {
			font-size: 18px;
		}
		&::after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			margin: 0 auto;
			width: 20px;
			height: 4px;
			background-color: $accent;
		}
	}
	&__text {
		text-align: center;
	}
}
