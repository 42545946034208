.horizontal-form {
	position: relative;
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
	z-index: 1;
	padding: 15px 0 5px;
	background-color: #e5e5e2;
}

.form-horizontal {
	& .contact-wrap-input {
		@include respond-to('larger') {
			font-size: 0;
			text-align: center;
		}
	}
	& .form-group {
		float: $right;
		vertical-align: top;
		margin: 0 0 0 20px;
		max-width: 340px;
		width: 100%;
		@include respond-to('larger') {
			// max-width: 260px;
			float: none;
			display: inline-block;
			margin: 0;
			padding: 0 8px;
			font-size: initial;
		}
		@include respond-to('1100') {
			// max-width: 23%;
		}
		@include respond-to('medium') {
			// max-width: 50%;
		}
		@include respond-to('extra-small') {
			padding: 0 4px;
			// max-width: 100%;
		}
		&_ml {
			margin-#{$left}: 0;
		}
		&_button {
			max-width: 90px;
			float: $left;
			margin: 0;
			// margin-#{$left}: 30px;
			@include respond-to('huge') {
				// max-width: 160px;
			}
			@include respond-to('larger') {
				display: block;
				margin: 0 auto;
				// max-width: 240px;
				width: 100%;
				float: none;
			}
			@include respond-to('extra-small') {
				// margin-top: 10px;
				// max-width: 44%;
				// min-width: 260px;
			}
		}
	}
	& .newslatter-input {
		height: 40px;
		width: 100%;
		padding: 5px 10px;
		// height: 100%;
		background-color: #fff;
		color: #949598;
		font-weight: 500;
		box-shadow: none;
		font-size: 18px;
		border: none;
		border-radius: 0;
		&:focus {
			outline: none;
			box-shadow: none;
			&::placeholder {
				opacity: 0;
				transition: opacity 0.3s ease;
			}
		}
		&::placeholder {
			font-size: 18px;
			color: #949598;
			opacity: 1;
			transition: opacity 0.3s ease;
		}
	}
	& .newslatter-btn {
		width: 100%;
		margin: 0;
		height: 40px;
		font-size: 18px;
		font-weight: 500;
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
		background-color: $title;
		border: none;
		color: #fff;
		&:active {
			@include btn-effect-active;
		}
	}
}

.help-block {
	font-size: 16px;
	color: $accent !important;
}

.form-control:focus {
	border-radius: 0;
}